import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { trans } from 'sharedKernel/i18n'
import { EVALUATOR_INVITE_STATUS } from 'sharedKernel/constants'
import { HubTable } from 'components/HubDefaultTable'
import { IInvite } from '../contracts'
import { dateFormater } from 'sharedKernel/handlers'
import { getInviteStatusLabel } from '../behavior'
import { ApproveRejectButtons } from 'components/ApproveRejectButtons'
import { TitleContainer } from 'components/HubDefaultTable/style'

interface HubEvaluatorInvitesTableProps {
  invites: IInvite[]
  acceptInvite: (inviteId: string) => void
  rejectInvite: (inviteId: string) => void
}

export const HubEvaluatorInvitesTable = ({ invites, acceptInvite, rejectInvite }: HubEvaluatorInvitesTableProps): JSX.Element => {
  const labels = {
    challenge: trans('Evento'),
    company: trans('Empresa organizadora'),
    applicationStartDate: trans('Início das avaliações'),
    status: trans('Status'),
    actions: trans('Ações')
  }

  const inviteStatus = {
    [EVALUATOR_INVITE_STATUS.INVITED]: {
      label: getInviteStatusLabel(EVALUATOR_INVITE_STATUS.INVITED),
      color: 'var(--yellow)'
    },
    [EVALUATOR_INVITE_STATUS.REFUSED]: {
      label: getInviteStatusLabel(EVALUATOR_INVITE_STATUS.REFUSED),
      color: 'var(--red)'
    },
    [EVALUATOR_INVITE_STATUS.ACCEPTED]: {
      label: getInviteStatusLabel(EVALUATOR_INVITE_STATUS.ACCEPTED),
      color: 'var(--green)'
    },
    [EVALUATOR_INVITE_STATUS.CANCELED]: {
      label: getInviteStatusLabel(EVALUATOR_INVITE_STATUS.CANCELED),
      color: 'var(--grey-light)'
    }
  }

  const tableHeader = (): any[] => {
    return [
      labels.challenge,
      labels.company,
      labels.applicationStartDate,
      labels.status,
      labels.actions
    ]
  }

  const getTableRows = (): any[] => {
    const evaluatorsTableParams = invites.map(invite => {
      const inviteActions = getInviteActions(invite)
      const inviteStatus = getInviteStatus(invite)
      const inviteRow: any[] = [
        <TitleContainer>{invite.challenge.config.title}</TitleContainer>,
        <TitleContainer>{invite.challenge.owner.name}</TitleContainer>,
        dateFormater(invite.challenge.config.step.evaluationStartDate, 'dd/MM/yyyy'),
        inviteStatus,
        inviteActions
      ]
      return inviteRow
    })
    return evaluatorsTableParams
  }

  const getInviteStatus = (invite: IInvite): JSX.Element => {
    return (
      <>
        <FontAwesomeIcon
          className="pr-2"
          color={inviteStatus[invite.status]?.color}
          icon={['fas', 'circle']}
        />
        {inviteStatus[invite.status]?.label}
      </>
    )
  }

  const getInviteActions = (invite: IInvite): JSX.Element => {
    if (invite.status !== EVALUATOR_INVITE_STATUS.INVITED) {
      return <></>
    }
    return <ApproveRejectButtons positiveAction={() => acceptInvite(invite.challenge.id)} negativeAction={() => rejectInvite(invite.challenge.id)} />
  }

  return (
    <HubTable
      header={tableHeader()}
      rows={getTableRows()}
    />
  )
}
