import React, { useEffect, useState } from 'react'
import { HubCompanyChallengeCarouselList } from 'domain/feature/hub/company/challenge/list'
import { GetFormat, useGet } from 'sharedKernel/api'
import { trans } from 'sharedKernel/i18n'
import { Challenge } from 'data/contracts'
import { NoContent } from 'components/NoContent'
import { CarouselHeader } from 'iebt-zing-storybook'
import { hasAccess } from 'sharedKernel/auth/behavior'
import { Button, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router'
import { Loading } from 'components/loading'
import NoContentImg from 'assets/images/defaults/no_challenges.png'
import { Filter } from 'components/Filter'
import { OrderBy, FilterOrderByProps } from 'components/OrderBy'
import { FilterOrderBar } from 'sharedKernel/styledComponents/filterComponents/style'

export const HubCompanyChallengePage = ({ ...props }): JSX.Element => {
  const navigate = useNavigate()

  const request: GetFormat = {
    url: '/v1/hub/company/challenge'
  }

  const orderByOptions = [
    { label: trans('Mais recente'), value: 'updated_at desc' },
    { label: trans('Ordem alfabética'), value: 'config.title' }
  ]

  const { data, isFetching, refetch } = useGet<Challenge[]>(request, 'company-challenges')
  const [isDelete, setIsDelete] = useState(false)
  const [selectedOrderBy, setSelectedOrderBy] = useState(orderByOptions[0])
  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>('')

  const labels = {
    challenges: trans('Eventos'),
    challengesInfo: trans('Veja os eventos cadastrados'),
    noContent: trans('Você ainda não possui nenhum evento.'),
    noContentFound: trans('Nenhum evento encontrado com o termo pesquisado.'),
    loading: trans('Carregando...'),
    newChallenge: trans('Criar Evento')
  }

  useEffect(() => {
    if (isDelete) {
      refetch()
      setIsDelete(false)
    }
  }, [refetch, isDelete])

  const onSearch = (searchTerm: string): void => {
    request.httpConfig = { params: { match: searchTerm } }
    setCurrentSearchTerm(searchTerm)
    refetch()
  }

  const onOrderBy = (selectedOrderBy: FilterOrderByProps): void => {
    request.httpConfig = { params: { sort: selectedOrderBy.value } }
    setSelectedOrderBy(selectedOrderBy)
    refetch()
  }

  const FilterOrderBarElement = (): JSX.Element => {
    return (
      <FilterOrderBar>
        <Filter currentSearchTerm={currentSearchTerm} btnClick={onSearch} />
        <OrderBy selectedValue={selectedOrderBy} onSelect={onOrderBy} options={orderByOptions} />
      </FilterOrderBar>
    )
  }

  const renderResponse = (): JSX.Element => {
    // eslint-disable-next-line no-constant-condition
    if (isFetching) {
      return (
        <Loading />
      )
    }

    if (data == null || data?.rows.length < 1) {
      return (
        <>
          <FilterOrderBarElement />
          <CarouselHeader
            title={labels.challenges}
            info={labels.challengesInfo}
            button={
              // eslint-disable-next-line multiline-ternary
              hasAccess('challenge', 'create') ? (
                <Button
                  variant="primary"
                  className="fw-bold fs-6 px-4"
                  style={{ letterSpacing: '1.5px' }}
                  onClick={() => {
                    navigate('/hub/company/challenge/template')
                  }}
                >
                  <FontAwesomeIcon icon={['fas', 'plus']} className="mr-3" />
                  {labels.newChallenge}
                </Button>
              ) : (
                <></>
              )
            }
          />
          <NoContent text={currentSearchTerm === '' ? labels.noContent : labels.noContentFound} image={NoContentImg} />
        </>
      )
    }

    return (
      <>
        <FilterOrderBarElement />
        <HubCompanyChallengeCarouselList challengeList={data?.rows ?? []} setIsDelete={setIsDelete} />
      </>
    )
  }

  return <>{renderResponse()}</>
}
