/* eslint-disable multiline-ternary */
import React from 'react'
import { trans } from 'sharedKernel/i18n'
import { Challenge } from 'data/contracts'
import { Badge, BadgeArea, BaseButton, CardHeader, OpenChallengesCard } from 'iebt-zing-storybook'
import { useNavigate } from 'react-router-dom'
import Tippy from '@tippyjs/react'
import { followCursor } from 'tippy.js'

import { ChallengeCardLimit } from './styles'
import { isCurrentDateBiggerThanDeadline, isStartDateGreaterThanCurrentDate, getUnnabreviatedDate } from 'sharedKernel/handlers'

interface ChallengeCardProps {
  challenge: Challenge
}

export const ChallengeCard = ({ challenge, ...props }: ChallengeCardProps): JSX.Element => {
  const navigate = useNavigate()
  const handleSignUp = (challengeId: string): void => {
    navigate(`/hub/startup/challenge/${challengeId}`)
  }

  const labels = {
    signUpSoon: trans('Inscreva-se em breve'),
    signUp: trans('Inscreva-se'),
    closed: trans('Encerrado'),
    solutionLimit: trans('Limite de soluções atingido'),
    signUpSoonTooltip: trans('Espere pela data de início'),
    signUpTooltip: trans('Inscreva-se no evento'),
    closedTooltip: trans('A data limite foi atingida'),
    solutionLimitTooltip: trans('Seu limite de soluções foi atingido')
  }

  const deadline = getUnnabreviatedDate(challenge.config.step.finalSelectionDate)
  const startDateNotReached = isStartDateGreaterThanCurrentDate(challenge.config.step.applicationStartDate)
  const isClosed = isCurrentDateBiggerThanDeadline(challenge.config.step.finalSelectionDate)

  const achievedSolutionsLimit = (): boolean => {
    const limitOfIdeas = challenge.config.limitOfIdeas
    const numberOfSolutions = challenge.config.numberOfSolutions
    return Number(numberOfSolutions) >= Number(limitOfIdeas)
  }

  const getCardHeader = (): JSX.Element => {
    return (
      <CardHeader coverImage={challenge.style.coverImagePath ?? ''} />
    )
  }

  const getCardBadge = (): JSX.Element => {
    return (
      <BadgeArea badgeQty={1}>
        <Badge deadline={deadline} icon={['fas', 'calendar-day']} type="string">
          {trans(deadline)}
        </Badge>
      </BadgeArea>
    )
  }

  const getCardButton = (): JSX.Element => {
    if (isClosed) {
      return (
        <Tippy
          content={labels.closedTooltip}
          followCursor={true}
          plugins={[followCursor]}
          animation="scale"
          arrow={false}
          theme="primary"
        >
          <ChallengeCardLimit
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
            }}
          >
            {labels.closed}
          </ChallengeCardLimit>
        </Tippy>
      )
    }

    if (achievedSolutionsLimit()) {
      return (
        <Tippy
          content={labels.solutionLimitTooltip}
          followCursor={true}
          plugins={[followCursor]}
          animation="scale"
          arrow={false}
          theme="primary"
        >
          <ChallengeCardLimit
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
            }}
          >
            {labels.solutionLimit}
          </ChallengeCardLimit >
        </Tippy>
      )
    }

    if (startDateNotReached) {
      return (
        <Tippy
          content={labels.signUpSoonTooltip}
          followCursor={true}
          plugins={[followCursor]}
          animation="scale"
          arrow={false}
          theme="primary"
        >
          <ChallengeCardLimit
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
            }}
          >
            {labels.signUpSoon}
          </ChallengeCardLimit>
        </Tippy>
      )
    }

    return (
      <Tippy
        content={labels.signUpTooltip}
        followCursor={true}
        plugins={[followCursor]}
        animation="scale"
        arrow={false}
        theme="primary"
      >
        <div>
          <BaseButton
            btnContent={labels.signUp}
            variant="risk-secondary"
            size="medium"
            onClick={() => handleSignUp(challenge.id)}
          />
        </div>
      </Tippy>
    )
  }

  const header = getCardHeader()
  const badge = getCardBadge()
  const button = getCardButton()

  return (
    <OpenChallengesCard
      header={header}
      programTitle={challenge.config.program.name}
      title={challenge.config.title}
      shortDescription={challenge.style.shortDescription}
      badges={badge}
      buttons={button}
      sendTo={() => handleSignUp(challenge.id)}
    />
  )
}
