import { BaseButton } from 'iebt-zing-storybook'
import React from 'react'
import { useNavigate } from 'react-router'
import { DeleteFormat, deleteProvider } from 'sharedKernel/api'
import { showDoubleActionPopUp } from 'components/popup/doubleAction'
import { showToast } from 'components/toast'
import { ChallengeButtonsProps } from '../contracts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { hasAccess } from 'sharedKernel/auth/behavior'
import { getHubRoute } from 'sharedKernel/handlers'
import { ROUTES } from 'sharedKernel/constants'
import { trans } from 'sharedKernel/i18n'

export const RemoveChallengeButton = ({ challengeId, isOwner }: ChallengeButtonsProps): JSX.Element => {
  const labels = {
    toolTipContent: trans('Remover')
  }
  const navigate = useNavigate()
  const haveAccess = hasAccess('challenge', 'delete')
  if (!haveAccess) {
    return <></>
  }
  if (!isOwner) {
    return <></>
  }
  const onSuccessDeletion = async (): Promise<void> => {
    showToast('success', 'Evento removido')
    navigate(getHubRoute(ROUTES.HUB.COMPANY.CHALLENGE_LIST))
  }

  const { mutate: deleteChallenge } = deleteProvider(onSuccessDeletion)
  const request: DeleteFormat = {
    url: `/v1/hub/company/challenge/${challengeId}`
  }
  const handleRemoveChallenge = (): void => {
    showDoubleActionPopUp({
      title: 'Excluir evento',
      message: 'Deseja realmente excluir esse evento?',
      buttons: [{ text: 'Sim' }, { text: 'Não' }],
      action: () => deleteChallenge(request)
    })
  }

  const icon = <FontAwesomeIcon icon={['fas', 'trash']} size="xl" />

  return (
    <BaseButton
      btnContent={icon}
      variant="primary-invert"
      size="icon"
      tippyContent={labels.toolTipContent}
      onClick={() => {
        handleRemoveChallenge()
      }}
    />
  )
}
