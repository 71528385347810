import { ITab } from '../../../../../../domain/feature/hub/company/challenge/FormWrapper/constants'
import { v4 as uuidv4 } from 'uuid'
import { NUMBER_OF_SUBSCRIBE_OPTIONS } from '../../../../../../sharedKernel/constants/index'

export const challengeForm: ITab[] = [
  {
    id: 'generalConfig',
    name: 'Configurações Gerais',
    content: [
      {
        id: 'title',
        label: 'Título',
        type: 'text',
        value: '',
        description: 'Campo Obrigatório',
        path: '',
        isDeleted: false,
        isRequired: true,
        maxLength: 140,
        validations: [
          {
            type: 'required',
            stage: 'save'
          },
          {
            type: 'maxLength',
            param: 140,
            stage: 'save'
          },
          {
            type: 'minLength',
            param: 4,
            stage: 'save'
          },
          {
            type: 'letterRequired',
            stage: 'all'
          }
        ],
        dataCy: 'challengeTitleInput'
      },
      {
        id: 'tagsNiches',
        path: '',
        type: 'typeahead',
        label: 'Segmento/setor',
        value: [],
        isDeleted: false,
        validations: [],
        dataCy: 'tagsNichesInput'
      },
      {
        id: 'shortDescription',
        label: 'Descrição curta',
        description: 'Campo Obrigatório',
        type: 'textarea',
        value: '',
        path: '',
        isDeleted: false,
        isRequired: true,

        maxLength: 280,
        validations: [
          {
            type: 'required',
            stage: 'launch'
          },
          {
            type: 'maxLength',
            param: 280,
            stage: 'save'
          },
          {
            type: 'minLength',
            param: 4,
            stage: 'launch'
          }
        ],
        dataCy: 'shortDescriptionInput'
      },
      {
        id: 'longDescription',
        label: 'Descrição Longa',
        type: 'textarea',
        value: '',
        path: '',
        maxLength: 5000,
        isDeleted: false,

        validations: [
          {
            type: 'maxLength',
            param: 5000,
            stage: 'save'
          }
        ],
        dataCy: 'longDescriptionInput'
      },
      {
        id: 'launchDate',
        label: 'Data de Início das Inscrições',
        type: 'datePicker',
        value: null,
        path: '',
        minDate: null,
        maxDate: null,
        isDeleted: false,
        isRequired: true,
        description: 'Campo Obrigatório',
        validations: [
          {
            type: 'required',
            stage: 'launch'
          }
        ],
        dataCy: 'lauchDateInput'
      },
      {
        id: 'applicationStartDate',
        label: 'Data de Aplicação',
        type: 'datePicker',
        value: null,
        path: '',
        minDate: null,
        maxDate: null,
        isDeleted: false,
        validations: [
          {
            type: 'required',
            stage: 'launch'
          }
        ],
        dataCy: 'applicationStartDateInput'
      },
      {
        id: 'finalSelectionDate',
        label: 'Data de Encerramento das Inscrições',
        type: 'datePicker',
        value: null,
        path: '',
        isDeleted: false,
        isRequired: true,
        description: 'Campo Obrigatório',
        validations: [
          {
            type: 'required',
            stage: 'launch'
          }
        ],
        dataCy: 'finalSelectionDateInput'
      },
      {
        id: 'evaluationStartDate',
        label: 'Data de Início das Avaliações',
        type: 'datePicker',
        value: null,
        path: '',
        isDeleted: false,
        isRequired: true,
        description: 'Campo Obrigatório',
        validations: [
          {
            type: 'required',
            stage: 'launch'
          }
        ],
        dataCy: 'evaluationStartDateInput'
      },
      {
        id: 'closingDate',
        label: 'Data de Encerramento das Avaliações',
        type: 'datePicker',
        value: null,
        path: '',
        isDeleted: false,
        isRequired: true,
        description: 'Campo Obrigatório',
        validations: [
          {
            type: 'required',
            stage: 'launch'
          }
        ],
        dataCy: 'closingDateInput'
      },
      {
        id: 'limitOfIdeas',
        label: 'Quantas vezes um solucionador pode submeter uma ideia para esse evento?',
        type: 'select',
        value: null,

        options: [...NUMBER_OF_SUBSCRIBE_OPTIONS],
        path: '',
        isDeleted: false,

        validations: [
          {
            type: 'required',
            stage: 'launch'
          }
        ],
        dataCy: 'limitOfIdeasSelect'
      },
      {
        id: 'youtubeLink',
        label: 'Link do youtube',
        type: 'youtubeLink',
        value: null,
        path: '',
        isDeleted: false,

        validations: [
          {
            type: 'pattern',
            stage: 'save'
          }
        ],
        dataCy: 'youtubeLinkInput'
      },
      {
        id: 'coverImage',
        acceptedFile: 'image/*',
        recommendedFormat: '256x104px',
        maxSize: '5 MB',
        description: 'Selecione uma imagem para ser exibida no Market Place. O tamanho ideal é de 256px X 104px',
        label: 'Imagem de Capa',
        type: 'uploadImage',
        isDeleted: false,
        path: '',
        removed: [],
        archive: {
          id: '',
          path: null,
          isDeleted: true,
          name: null,
          size: null,
          mimetype: null,
          extension: null,
          type: null,
          content: null,
          sourceFile: null
        },
        dataCy: 'addCoverImage'
      },
      {
        id: 'homePageSlider',
        acceptedFile: 'image/*',
        recommendedFormat: '1120x240px',
        maxSize: '5 MB',
        label: 'Imagem do Evento',
        description:
          'Selecione uma imagem para ser exibida no detalhamento do evento. O tamanho ideal é de 1120px X 240px',
        type: 'uploadImage',
        isDeleted: false,
        path: '',
        removed: [],
        archive: {
          id: '',
          path: null,
          isDeleted: true,
          name: null,
          size: null,
          mimetype: null,
          extension: null,
          type: null,
          content: null,
          sourceFile: null
        },
        dataCy: 'addHomePageSlider'
      },
      {
        id: 'presentationVideo',
        acceptedFile: 'video/*',
        recommendedFormat: '420x116px',
        maxSize: '50 MB',
        type: 'uploadVideo',
        isDeleted: false,
        label: 'Vídeo Apresentativo',
        description:
          'Selecione um video para ser exibido no detalhamento do evento. O tamanho ideal é de 420px X 116px',
        path: '',
        removed: [],
        archive: {
          id: '',
          path: null,
          isDeleted: true,
          name: null,
          size: null,
          mimetype: null,
          extension: null,
          type: null,
          content: null,
          sourceFile: null
        },
        dataCy: 'addPresentationVideo'
      },
      {
        id: 'supportFiles',
        maxSize: '50 MB',
        label: 'Documentos de Apoio',
        description: 'Selecione arquivos que serão importantes para os solucionadores entenderem melhor seu evento',
        type: 'multiFile',
        value: null,
        path: '',
        isDeleted: false,
        removed: [],
        archives: [],
        dataCy: 'addSupportFiles'
      }
    ]
  },
  {
    id: 'applicationForm',
    name: 'Configure o Formulário de Aplicação',
    content: [
      {
        id: `${String(uuidv4())}_idScreen`,
        type: 'applicationForm',
        name: 'Application Form',
        sections: []
      }
    ]
  },
  {
    id: 'evaluation',
    name: 'Configure o Formulário de Avaliação',
    content: [
      {
        id: `${String(uuidv4())}_idScreen`,
        name: '',
        type: 'evaluation',
        rounds: 1,
        likertScale: 5,
        steps: []
      }
    ]
  }
]
