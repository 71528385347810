import React from 'react'
import { IHubChallengeBasicFormState } from 'store'
import { GetFormat, useGet } from 'sharedKernel/api'
import { FormChallengeWrapper } from 'domain/feature/hub/company/challenge/FormWrapper/style'
import { CarouselHeader } from 'iebt-zing-storybook'
import { HubCompanyChallengeTemplateCarouselList } from 'domain/feature/hub/company/challenge/template'
import { Loading } from 'components/loading'
import { trans } from 'sharedKernel/i18n'
import { PageDescription, PageTitle } from 'domain/feature/hub/pageTitle/styles'

export const HubCompanyChallengeTemplatePage = (): JSX.Element => {
  const request: GetFormat = {
    url: '/v1/hub/company/challenge/templates'
  }

  const { data, isError, isFetching } = useGet<IHubChallengeBasicFormState>(request, 'challenge-templates')

  const labels = {
    title: trans('Criar novo Evento')
  }

  const renderResponse = (): JSX.Element => {
    if (isFetching) {
      return (
        <Loading />
      )
    }

    return <HubCompanyChallengeTemplateCarouselList templateList={data.rows} />
  }

  return (
    <>
      <FormChallengeWrapper>
        <PageTitle>{labels.title}</PageTitle>

        <CarouselHeader title="Escolha um modelo" />
        <p className="fs-6 fw-light lh-base">Crie um novo evento ou trabalhe a partir de um modelo já existente!</p>
        {renderResponse()}
      </FormChallengeWrapper>
    </>
  )
}
