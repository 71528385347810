import React from 'react'
import { GetFormat, useGet } from 'sharedKernel/api'
import { trans } from 'sharedKernel/i18n'
import { HubCompanyDashboardStatsCard } from '../StatsCard'
import { HubCompanyChallengeDashboardEvaluatorStatsApiResult } from './contracts'
import { loggedUser } from 'sharedKernel/user/LoggedUser'

export const HubCompanyDashboardEvaluatorCounter = (): JSX.Element => {
  const labels = {
    statsName: trans('Comunidade'),
    statsDescription: trans('Atuantes'),
    statsInfo: trans('Total de Avaliadores')
  }

  const statsProps: any = {
    name: labels.statsName,
    count: 0,
    description: labels.statsDescription,
    info: labels.statsInfo,
    cardLabel: {
      icon: ['fas', 'user-check'],
      color: 'var(--yellow)',
      background: 'var(--yellow-light)',
      size: '2x'
    }
  }

  const request: GetFormat = {
    url: '/v1/hub/company/dashboard/evaluator/total'
  }
  const { data, isError, isLoading, isFetching } = useGet<HubCompanyChallengeDashboardEvaluatorStatsApiResult>(
    request,
    'evaluator-stats-get'
  )

  if (isFetching || isLoading || isError) {
    return <HubCompanyDashboardStatsCard {...statsProps} />
  }

  if (data.data.accepted === null || data.data.accepted === undefined) {
    return <HubCompanyDashboardStatsCard {...statsProps} />
  }

  statsProps.count = data.data.accepted
  return <HubCompanyDashboardStatsCard {...statsProps} />
}
