/* eslint-disable no-extra-boolean-cast */
import React, { useState } from 'react'
import { postProvider, PostFormat, deleteProvider, postCall } from '../../../../../../../sharedKernel/api'
import { showToast } from 'components/toast'
import { useNavigate } from 'react-router-dom'
import { trans } from '../../../../../../../sharedKernel/i18n'
import { IHubStartupChallengeApplicationFileStructure, IHubStartupChallengeApplicationForm, TReducers, useSelector } from 'store'
import { Button } from 'react-bootstrap'
import { toBase64 } from 'sharedKernel/file/handler'
import { getHubRoute } from 'sharedKernel/handlers'
import { ROUTES } from 'sharedKernel/constants'
import { showModal } from 'components/popup'

interface HubStartupIdeaSaveButtonProps {
  data: IHubStartupChallengeApplicationForm
  text?: string
  launch?: boolean
  setIsTitleEmpty: Function
  setValidateForm: Function
  setFormValidated: (launch: boolean, titleEmpty: boolean) => boolean
}

export function HubStartupIdeaSaveButton({
  data,
  text = 'Salvar',
  launch = false,
  setIsTitleEmpty,
  setValidateForm,
  setFormValidated
}: HubStartupIdeaSaveButtonProps): JSX.Element {
  const applicationFormState = useSelector((state: TReducers) => state.startupHubChallengeApplicationFormState)
  const applicationFormFilesState = useSelector((state: TReducers) => state.startupHubChallengeApplicationFormFilesState)
  const [deleteFileName, setDeleteFileName] = useState('')
  const [successFiles, setSuccessFiles] = useState<any>([])

  const dataWithoutFilesSections = data.sections.map(section => {
    const newAnswers = section.answers.map(answer => {
      if (typeof answer.value === 'object') {
        return { ...answer, value: '' }
      } else {
        return answer
      }
    })
    return { ...section, answers: newAnswers }
  })
  const dataWithoutFiles: IHubStartupChallengeApplicationForm = { ...data, sections: dataWithoutFilesSections }

  const labels = {
    success: trans('Ideia salva com sucesso'),
    fail: trans('Houve um erro ao salvar a ideia'),
    uploadFileSuccess: trans('O arquivo {} foi salvo com sucesso'),
    uploadFileFail: trans('Falha no upload do arquivo {}'),
    deleteFileSuccess: trans('O arquivo {} foi removido com sucesso'),
    deleteFileFail: trans('Falha na remoção do arquivo {}'),
    launchSuccess: trans('Parabéns, sua inscrição foi enviada para aprovação!'),
    launchFail: trans('Houve um erro ao lançar a ideia'),
    emptyTitle: trans('O título da ideia não pode ser vazio!'),
    yes: trans('Sim'),
    no: trans('No'),
    confirmationMessage: launch ? trans('Deseja lançar sua solução para este Evento?') : trans('Deseja salvar sua solução para este Evento?'),
    confirmationTitle: launch ? trans('Publicar') : trans('Salvar'),
    requiredFields: trans('Preencha todos os campos obrigatórios para publicar')
  }

  const onSuccessUploadFile = async (uploadFileName: string): Promise<void> => {
    setSuccessFiles([...successFiles, 'upload' + uploadFileName])
    showToast('success', labels.uploadFileSuccess.replace('{}', uploadFileName))
  }
  const onErrorUploadFile = async (uploadFileName: string): Promise<void> => {
    showToast('error', labels.uploadFileFail.replace('{}', uploadFileName))
  }

  const onSuccessDeleteFile = async (): Promise<void> => {
    setSuccessFiles([...successFiles, 'delete' + deleteFileName])
    showToast('success', labels.deleteFileSuccess.replace('{}', deleteFileName))
  }
  const onErrorDeleteFile = async (): Promise<void> => {
    showToast('error', labels.deleteFileFail.replace('{}', deleteFileName))
  }

  const successfullySavedIdeaMessage = async (): Promise<void> => {
    showToast('success', labels.success)
  }

  const unsuccessfullySavedIdeaMessage = async (errorMessage: string): Promise<void> => {
    showToast('error', errorMessage)
  }

  const navigate = useNavigate()
  const { mutate: deleteFile } = deleteProvider(onSuccessDeleteFile, onErrorDeleteFile)
  const { mutate: persistLaunchIdea } = postProvider()
  const { mutate: persistIdea } = postProvider(successfullySavedIdeaMessage)
  const [isDisabled, setIsDisabled] = useState(false)

  const handleSave = (): void => {
    const isTitleEmpty = applicationFormState.title === ''
    setIsTitleEmpty(isTitleEmpty)
    if (launch) {
      setValidateForm(true)
    }
    if (setFormValidated(launch, isTitleEmpty)) {
      showConfirmationModal()
    }
  }

  const saveIdea = async (): Promise<void> => {
    setIsDisabled(true)
    await deleteFiles(applicationFormFilesState.deletedApplicationFormFiles)
    persistIdea(preparePostData(dataWithoutFiles), {
      onSuccess: async (response) => {
        const solutionId = response.data.id
        await uploadFiles(solutionId, applicationFormFilesState.applicationFormFiles).then(() => {
          if (launch) {
            launchIdea(solutionId)
          } else {
            goToSolutions()
          }
        }).catch(() => {
          if (launch) {
            showToast('error', labels.launchFail)
          }
          goToSolutions()
        })
      },
      onError: (error) => {
        unsuccessfullySavedIdeaMessage(error.response.data.errors[0].message)
        setIsDisabled(false)
      }
    })
  }

  const goToSolutions = (): void => {
    navigate(getHubRoute(ROUTES.HUB.STARTUP.IDEA_LIST))
  }

  const launchIdea = (ideaId: string): void => {
    persistLaunchIdea(
      { url: `/v1/hub/resolver/solution/${ideaId}/launch` },
      {
        onSuccess: () => {
          showToast('success', labels.launchSuccess)
        },
        onError: () => {
          showToast('error', labels.launchFail)
          setIsDisabled(false)
        }
      }
    )
    setTimeout(goToSolutions, 1500)
  }

  const processUploadedFiles = async (files: IHubStartupChallengeApplicationFileStructure[]): Promise<IHubStartupChallengeApplicationFileStructure[]> => {
    return await Promise.all(
      files.map(async (file): Promise<IHubStartupChallengeApplicationFileStructure> => {
        let fileContent, mimetype
        if (file.sourceFile !== undefined) {
          fileContent = await toBase64(file.sourceFile)
          mimetype = file.sourceFile.type
        }

        return {
          mimetype: mimetype,
          name: btoa(unescape(encodeURIComponent(file.name))),
          size: file.size,
          content: fileContent,
          sectionId: file.sectionId,
          questionId: file.questionId,
          type: file.type
        }
      })
    )
  }

  const deleteFiles = async (files: IHubStartupChallengeApplicationFileStructure[] = []): Promise<void> => {
    files.forEach((file) => {
      setDeleteFileName(file.name)
      deleteFile({
        url: `v1/hub/resolver/challenge/${applicationFormState.challenge.id!}/file/${file.id!}`
      })
    })
  }

  const uploadFiles = async (solutionId: string, files: IHubStartupChallengeApplicationFileStructure[] = []): Promise<any> => {
    const newFiles = files.filter((file) => file.id == null)
    const processedFiles = await processUploadedFiles(newFiles)
    const promises: Array<Promise<void>> = []
    processedFiles.forEach((file) => {
      const fileName = atob(file.name)
      const data = { content: file.content, mimetype: file.mimetype, name: file.name, type: file.type }
      promises.push(
        postCall({
          url: `v1/hub/resolver/solution/${solutionId}/section/${file.sectionId}/field/${file.questionId}/upload`,
          data: { data: data }
        }).then(async () => onSuccessUploadFile(fileName))
          .catch(async () => onErrorUploadFile(fileName)))
    })
    return await Promise.all(promises)
  }

  function preparePostData(ideaApiData: IHubStartupChallengeApplicationForm): PostFormat {
    const postObject: PostFormat = {
      url: 'v1/hub/resolver/solution',
      data: {
        data: ideaApiData
      }
    }
    return postObject
  }

  const showConfirmationModal = (): any => {
    showModal('double', {
      title: labels.confirmationTitle,
      message: labels.confirmationMessage,
      buttons: [{ text: labels.yes }, { text: labels.no }],
      action: async () => saveIdea()
    })
  }

  return (
    <Button
      disabled={isDisabled}
      variant="primary"
      onClick={() => handleSave()}
    >
      {text}
    </Button>
  )
}
