/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { BaseButton } from 'iebt-zing-storybook'
import React, { useState } from 'react'
import { updateProvider } from 'sharedKernel/api'
import { hasAccess } from 'sharedKernel/auth/behavior'
import { trans } from 'sharedKernel/i18n'
import { showDoubleActionPopUp } from 'components/popup/doubleAction'
import { showToast } from 'components/toast'
import { ChallengeButtonsProps } from '../contracts'
import { useNavigate } from 'react-router'

export const LaunchChallengeButton = ({
  challengeId,
  isOwner,
  alreadyLaunched
}: ChallengeButtonsProps): JSX.Element => {
  const haveAccess = hasAccess('challenge', 'launch')
  const labels = {
    btnText: 'Publicar',
    launchSuccess: 'Evento publicado com sucesso',
    launchError: 'O evento não pode ser publicado. Verifique os campos marcados de vermelho no formulário'
  }
  const { mutate: persistLaunchChallenge } = updateProvider()
  const navigate = useNavigate()

  const launchChallenge = async (): Promise<void> => {
    persistLaunchChallenge(
      { url: `/v1/hub/company/challenge/${challengeId ?? ''}/launch` },
      {
        onSuccess: () => {
          showToast('success', labels.launchSuccess)
          navigate(`/hub/company/challenge/${challengeId}`, {
            state: { setToLaunched: true }
          })
        },
        onError: () => {
          showToast('error', labels.launchError)
          navigate(`/hub/company/challenge/${challengeId}/edit?validate=launch`, {
            state: { setToLaunched: true }
          })
        }
      }
    )
  }

  const handleLaunchChallenge = (): void => {
    showDoubleActionPopUp({
      title: 'Lançar evento',
      message: 'Deseja realmente lançar esse evento?',
      buttons: [{ text: 'Sim' }, { text: 'Não' }],
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      action: async () => launchChallenge()
    })
  }

  if (!haveAccess || !isOwner || alreadyLaunched) {
    return <></>
  }
  return (
    <>
      <BaseButton
        btnContent={trans(labels.btnText)}
        variant="primary"
        size="big"
        isFullWidth
        onClick={() => {
          handleLaunchChallenge()
        }}
      />
    </>
  )
}
