import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { api, GetFormat } from 'sharedKernel/api'
import { NoContent } from 'components/NoContent'
import { Loading } from 'components/loading'
import { showToast } from 'components/toast'
import {
  changeChallengeFiles,
  changeConfigSection,
  fullResetChallenge,
  IFileStructure,
  IHubChallengeBasicFormFilesState,
  IHubChallengeBasicFormState,
  Response,
  store
} from 'store'
import { HubCompanyNewChallengePage } from '../new'

function formatDate(date?: string | null): string | null {
  if (date !== null && date !== undefined && date !== '') {
    const newDate = date.replace('T00:00:00', '')
    return newDate
  }

  return null
}

export const fixDatas = (_challengeState: IHubChallengeBasicFormState): void => {
  _challengeState.config.step.launchDate = formatDate(_challengeState.config.step.launchDate)
  _challengeState.config.step.applicationStartDate = formatDate(_challengeState.config.step.applicationStartDate)
  _challengeState.config.step.finalSelectionDate = formatDate(_challengeState.config.step.finalSelectionDate)
  _challengeState.config.step.evaluationStartDate = formatDate(_challengeState.config.step.evaluationStartDate)
  _challengeState.config.step.closingDate = formatDate(_challengeState.config.step.closingDate)
}

export const HubCompanyChallengeEditPage = (props): any => {
  const { challengeId } = useParams()
  const [challengeState, setChallengeState] = useState<any>()
  const [challengeStateFile, setChallengeStateFile] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const labels = { noContent: 'Evento não encontrado', noFiles: 'Falha ao buscar arquivos' }

  const request: GetFormat = {
    url: `v1/hub/company/challenge/${challengeId}`
  }
  const filesRequest: GetFormat = {
    url: `v1/hub/company/challenge/${challengeId}/files`
  }

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        store.dispatch(fullResetChallenge(challengeState))

        const response1 = await api.get<Response>(request.url)
        setChallengeState({ ...response1.data.data, id: challengeId })

        const response2 = await api.get(filesRequest.url)
        setChallengeStateFile(response2.data)

        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [])

  if (challengeId !== undefined) {
    if (isLoading) {
      return (
        <Loading />
      )
    }

    if (challengeState === null || challengeState === undefined) {
      return <NoContent text={labels.noContent} />
    }

    fixDatas(challengeState)
    store.dispatch(changeConfigSection(challengeState))

    if (challengeStateFile.rows != null) {
      const files: IFileStructure[] = challengeStateFile.rows

      const filesChallengeState: IHubChallengeBasicFormFilesState = {
        challengeFiles: files.filter((file) => file.type === 'supportFiles'),
        deletedChallengeFiles: [],
        homePageSlider: files.find((file) => file.type === 'homePageSlider'),
        presentationVideo: files.find((file) => file.type === 'presentationVideo'),
        coverImage: files.find((file) => file.type === 'coverImage')
      }
      store.dispatch(changeChallengeFiles(filesChallengeState))
    } else {
      showToast('error', labels.noFiles)
    }
  }

  return <HubCompanyNewChallengePage />
}
